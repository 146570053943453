.page-header {
    height: 40vh;
    background-image: url('./img/background-3.jpg');
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(transparent -30%, #000);
    }
    .header-content-wrapper {
        color: #fff;
        position: relative;
        padding: 50px 60px;
        h2 {
            font-size: 42px;
            font-weight: 600;
            margin: 0;
            margin-bottom: 10px;
        }
        p {
            margin: 0;
        }
        .btn-wrapper {
            margin-top: 40px;
            a {
                border: 1px solid;
                padding: 12px 20px;
                border-radius: 30px;
                margin-right: 20px;
                text-transform: uppercase;
                font-weight: 600;
                font-size: 13px;
            }
        }
    }
}