.single-track {
    margin-right: 30px;
    margin-bottom: 20px;
    .cover-wrapper {
        position: relative;
        height: 200px;
        width: 200px;
        background-size: cover;
        border-radius: 4px;
        box-shadow: 0 0 40px 0 rgba(82, 63, 105, 0.1);
        .cover-wrapper-overlay {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            .play-pause-btn {
                background-color: #fff;
                border-radius: 50%;
                height: 65px;
                width: 65px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    height: 55px;
                    cursor: pointer;
                }
            }
        }
        &:hover {
            .cover-wrapper-overlay {
                opacity: 1;
            } 
        }
    }
    .description-wrapper {
        color: #fff;
        .description-wrapper-content {
            h3 {
                font-size: 16px;
                font-weight: 500;
                margin: 15px 0 0 0;
            }
            p {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.7);
                margin: 5px 0 0 0;
                max-width: 150px;
            }
        }
    }
}
