.toplist {
    padding: 20px 30px 0 30px;
    h4 {
        color: #fff;
        font-size: 14px;
        margin-bottom: 25px;
        margin-top: 0;
    }
    .single-track {
        display: flex;
        align-items: center;
        margin: 0;
        margin-bottom: 18px;
        span {
            color: #fff;
            margin-right: 15px;
            width: 20px;
        }
        .cover-wrapper {
            height: 50px;
            width: 50px;
            margin-right: 15px;
            .cover-wrapper-overlay .play-pause-btn {
                height: 30px;
                width: 30px;
                img {
                    height: 20px;
                }
            }
        }
        .description-wrapper {
            .description-wrapper-content {
                h3 {
                    margin: 0;
                }
            }
        }
    }
}