@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900&display=swap&subset=latin-ext');


body {
  background-color: #000;
  font-family: 'Montserrat', sans-serif;
}

a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
}

.btn {
  background-color: transparent;
  color: #fff;
  transition: .3s;
  &:hover {
    background-color: #fff;
    color: #000;
  }
}

a, .btn {
  outline: none;
  cursor: pointer;
}

.app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  .content-wrapper {
    display: flex;
    height: 100vh;
    .aside {
      background-color: #000;
      position: fixed;
      width: 320px;
      height: 100%;
      box-shadow: 0 0 40px 0 rgba(82,63,105,.1);
      .logo-wrapper {
        display: flex;
        align-items: center;
        padding: 30px;
        h1 {
          color: #fff;
          margin: 0;
          font-size: 18px;
          font-weight: 600;
        }
        img {
          max-width: 25px;
          margin-right: 9px;
          position: relative;
          top: 1px;
        }
      }
      .menu {
        ul {
          list-style: none;
          padding: 0 30px;
          margin: 10px 0;
          li {
            margin-bottom: 12px;
            a {
              display: flex;
              align-items: center;
              letter-spacing: 1px;
              font-size: 15px;
              &.active {
                color: #fff;
                svg {
                  .fillme {
                    fill: #fff;
                  }
                }
              }
              svg {
                margin-right: 15px;
                .fillme {
                  fill: rgba(255, 255, 255, 0.7);
                }
              }
            }
          }
        }
      }
    }
    .main {
      width: 100%;
      padding-left: 320px;
    }
  }
  .player {
    position: fixed;
    bottom: 0;
    height: 50px;
    padding: 15px 30px;
    width: calc(100% - 60px);
    background-color: #000;
    box-shadow: 0 0 40px 0 rgba(82,63,105,.1);
    display: flex;
    align-items: center;
    svg {
      margin-right: 20px;
    }
    img {
      height: 100%;
      width: auto;
      margin-right: 20px;
      border-radius: 4px;
    }
    .fillme {
      fill: #fff;
    }
    .information {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 20px;
      p {
        margin: 0;
        &.title {
          color: #fff;
          font-weight: 500;
        }
        &.artist {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
    time {
      margin-right: 20px;
    }
    .playedTime {
      color: #fff;
    }
    .wholeTime {
      color: #fff;
    }
    input[type="range"] {
      -webkit-appearance: none;
      height: 1px;
      border-radius: 5px;
      background: #fff;
      outline: none;
      padding: 0;
      margin: 0;
      &::-webkit-slider-thumb {
        appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        //background-image: radial-gradient(ellipse, transparent, #61d5ec 90%);
        background-color: #fff;
        cursor: pointer;
        transition: background .15s ease-in-out;
        &::before {
          content: '';
          height: 5px;
          width: 5px;
          position: absolute;
        }
        &:hover {
          background: #fff;
        }
      }
      &:active::-webkit-slider-thumb {
        background: #fff;
      }
      &::-moz-range-thumb {
        width: 30px;
        height: 30px;
        border: 0;
        border-radius: 50%;
        background: #fff;
        cursor: pointer;
        transition: background .15s ease-in-out;
        &:hover {
          background: #fff;
        }
      }
      &:active::-moz-range-thumb {
        background: #fff;
      }
      &.durationRage {
        flex: 1;
        margin-right: 20px;
      }
      &.volumeRage {
        max-width: 190px;
      }
    }
    .volumeRage-wrapper {
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.happiness-page {
  .tracks-wrapper {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    top: -60px;
    padding: 0 60px 30px 60px;
  }
}

.radio-page {
  .button-wrapper {
    padding-left: 60px;
    button {
      border: 1px solid #fff;
      padding: 12px 20px;
      border-radius: 30px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 13px;
    }
  }
}